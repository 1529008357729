import { differenceInDays, differenceInHours } from "date-fns"

/**
 * Takes an array of data and splits into chunks where each chunk has a dataset
 * where the time of the events happen within the given resolution
 *
 * @param {array} data Data to split into chunks
 * @param {number} resolution Maximum allowed difference in seconds between two datapoints
 * @param {number} slack Allowed number of seconds that the difference is allowed to slack
 */
export function splitToChunks(
  data: { value: number; dateTimeUtc: string }[],
  resolution: number = 60,
  slack: number = 0
) {
  const chunks: { value: number; dateTimeUtc: string }[][] = []

  let chunkIndex = 0

  data.map((curr, i) => {
    const next = data[i + 1]

    // Make sure chunk array is defined
    if (!chunks[chunkIndex]) {
      chunks[chunkIndex] = []
    }

    chunks[chunkIndex]?.push(curr)

    if (
      next &&
      !isValid(
        new Date(curr.dateTimeUtc),
        new Date(next.dateTimeUtc),
        resolution,
        slack
      )
    ) {
      chunkIndex++
    }

    return null
  })

  return chunks
}

/**
 * Returns the difference between two dates in seconds. The calculation is not
 * dependant on the order of the dates.
 * @param {date} d1
 * @param {date} d2
 */
function isValid(d1: Date, d2: Date, resolution: number, slack: number) {
  return secondsBetweenDates(d1, d2) <= resolution + slack
}

/**
 * Returns the difference between two dates in seconds. The calculation is not
 * dependant on the order of the dates.
 * @param {date} d1
 * @param {date} d2
 */
function secondsBetweenDates(d1: Date, d2: Date) {
  return Math.abs(d1.getTime() - d2.getTime()) / 1000
}

export function getMotionResolution({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) {
  const minute = 60
  const hour = 3600
  const day = 24 * hour
  const dayDiff = differenceInDays(endDate, startDate)
  const hourDiff = differenceInHours(endDate, startDate)

  if (hourDiff <= 24) {
    return minute * 15
  } else if (dayDiff <= 10) {
    return hour * 2
  } else if (dayDiff <= 28) {
    return hour * 8
  } else if (dayDiff <= 28 * 3) {
    return day
  }
  return day * 3
}

export function getResolution({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) {
  const diff = differenceInDays(endDate, startDate)
  const hours = differenceInHours(endDate, startDate)

  if (hours <= 2) {
    return 60 // 1 minute
  } else if (hours <= 6) {
    return 60 * 2 // 2 minutes
  } else if (hours <= 24) {
    return 60 * 7 // 7 minutes
  } else if (diff <= 5) {
    return 60 * 15 // 15 minutes
  } else if (diff <= 10) {
    return 60 * 30 // 30 minutes
  } else if (diff <= 20) {
    return 60 * 60 * 2 // 30 minutes
  } else if (diff < 80) {
    return 60 * 60 * 2 // 2 hours
  } else if (diff < 500) {
    return 60 * 60 * 24 * 2 // 2 days
  } else {
    return 60 * 60 * 24 * 6 // 6 days
  }
}
